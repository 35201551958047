var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g({}, _vm.onVisible()),
    [
      _c(
        "mdb-modal-header",
        [_c("mdb-modal-title", [_vm._v("Related Product Selection")])],
        1
      ),
      _c("mdb-modal-body", { staticClass: "px-2" }, [
        _c(
          "ul",
          { staticClass: "border" },
          _vm._l(_vm.supplierProductsSoldSeparately, function (item, index) {
            return _c(
              "li",
              { key: index, staticClass: "d-flex" },
              [
                _c("awgt-input", {
                  attrs: {
                    id: "supplierProduct_" + item.reference,
                    type: "checkbox",
                    label: item.name,
                  },
                  model: {
                    value: item.checked,
                    callback: function ($$v) {
                      _vm.$set(item, "checked", $$v)
                    },
                    expression: "item.checked",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c("mdb-modal-footer", [
        _c(
          "div",
          { staticClass: "d-flex float-right" },
          [
            _c(
              "awgt-std-button",
              {
                staticClass: "command-button",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.onClose()
                  },
                },
              },
              [_vm._v("Close")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }