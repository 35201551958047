<template>
  <div>
    <div
      class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3"
    >
      <awgt-std-button
        type="button"
        class="command-button mx-2"
        @click="onEdit"
      >
        <mdb-icon icon="edit" class="mr-1" />Edit
      </awgt-std-button>
    </div>

    <div class="d-flex flex-grow-1">
      <span class="flex-fill font-weight-bolder pl-2">Component</span>
      <span class="flex-fill font-weight-bolder">Price</span>
      <span class="flex-fill font-weight-bolder">Validity</span>
      <span style="width: 60px"></span>
    </div>
    <mdb-card
      v-for="(rp, index) in data"
      :key="index"
      class="py-1 mb-1"
      style="background-color: #f5f5f5"
    >
      {{ rp.Name }}
      <button
        class="d-flex align-items-center"
        @click="toggleCommercialProductLineExpansion(index)"
        style="
          border: none;
          background-color: inherit;
          text-align: left;
          color: inherit;
        "
        type="button"
      ></button>
      <expansion-region
        :toggle="relatedProductExpansion[index] == true"
        useVShowConditional
        tag="div"
        class="mx-3"
      >
        <mdb-card-body class="pt-1">
          <!-- <product-view :value="product"></product-view> -->
        </mdb-card-body>
      </expansion-region>
    </mdb-card>
    <mdb-modal
      size="lg"
      v-if="showRelatedProductSelectionDialog == true"
      :show="showRelatedProductSelectionDialog"
    >
      <related-product-selection-dialog
        v-model="data"
        :providerSupplierReference="providerSupplierReference"
        :currentProductReference="currentProductReference"
        @close="onProductSelectionDialogClose"
        @input="onInput"
      ></related-product-selection-dialog>
    </mdb-modal>
  </div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<style lang="scss" scoped>
.flex-fill {
  flex-basis: 0 !important;
}
.bold {
  font-weight: bolder;
}
</style>

<script>
import { mdbIcon, mdbModal, mdbCard, mdbCardBody } from "mdbvue";
import ExpansionRegion from "@/components/ExpansionRegion";
import RelatedProductSelectionDialog from "./relatedProductSelectionDialog";
import { sharedMethods } from "@/shared/shared";
//import ProductView from "@/components/ProductView";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  // name: "product-component-pricing",
  components: {
    mdbIcon,
    AwgtStdButton,
    ExpansionRegion,
    mdbModal,
    mdbCard,
    mdbCardBody,
    RelatedProductSelectionDialog,
    //ProductView,
  },

  data() {
    return {
      relatedProductExpansion: [],
      showRelatedProductSelectionDialog: false,
      data: [],
    };
  },

  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },

    providerSupplierReference: {
      type: String,
      default: "",
    },

    currentProductReference: {
      type: String,
      require: true,
    },
  },

  watch: {
    value(to, from) {
      if (to != from) {
        this.data = this.$lodash.cloneDeep(to);
      }
    },
  },

  methods: {
    ...sharedMethods,

    onEdit() {
      this.data = this.$lodash.cloneDeep(this.value);
      this.showRelatedProductSelectionDialog = true;
    },

    toggleCommercialProductLineExpansion(index) {
      this.$set(
        this.relatedProductExpansion,
        index,
        !this.relatedProductExpansion[index]
      );
    },

    onProductSelectionDialogClose() {
      this.showRelatedProductSelectionDialog = false;
    },

    onInput() {
      this.$emit("input", this.data);
    },
  },

  mounted() {
    this.data = this.$lodash.cloneDeep(this.value);
  },
};
</script>
