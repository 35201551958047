var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._g({}, _vm.onVisible()),
    [
      _c("asoft-modal-header", {
        attrs: { label: "Product Pricing" },
        on: {
          close: () => {
            if (_vm.showEditPane == true) _vm.onCancel()
            else _vm.onClose()
          },
        },
      }),
      _c(
        "mdb-modal-body",
        { staticClass: "px-2" },
        [
          _vm.isDateOverlap()
            ? _c("div", { staticClass: "error-msg mb-2" }, [
                _c("b", [_vm._v("Warning: overlapping dates.")]),
              ])
            : _vm._e(),
          _vm._l(
            _vm.commercialProductLine.Chronology,
            function (chronology, index) {
              return _c(
                "div",
                {
                  key: chronology.CommercialProductLineChronologyId,
                  staticClass: "px-2 py-2 border d-flex",
                  class: [
                    {
                      currentComponentChronology:
                        new Date(chronology.ActiveFromDt) <= new Date() &&
                        (chronology.ActiveToDt == null ||
                          new Date(chronology.ActiveToDt) >= new Date()),
                    },
                  ],
                },
                [
                  _c("div", [
                    _c("h4", [_vm._v(_vm._s(chronology.Name))]),
                    chronology.ActiveToDt == null
                      ? _c("div", { staticClass: "flex-fill" }, [
                          _vm._v(
                            " From " +
                              _vm._s(
                                _vm.convertDateFormat(
                                  chronology.ActiveFromDt,
                                  _vm.$constants.DATETIMEFORMAT_Json,
                                  _vm.$constants.DATETIMEFORMAT_Presentation
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _c("div", { staticClass: "flex-fill" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.convertDateFormat(
                                  chronology.ActiveFromDt,
                                  _vm.$constants.DATETIMEFORMAT_Json,
                                  _vm.$constants.DATETIMEFORMAT_Presentation
                                )
                              ) +
                              " - " +
                              _vm._s(
                                _vm.convertDateFormat(
                                  chronology.ActiveToDt,
                                  _vm.$constants.DATETIMEFORMAT_Json,
                                  _vm.$constants.DATETIMEFORMAT_Presentation
                                )
                              ) +
                              " "
                          ),
                        ]),
                    _c("div", [
                      chronology.PriceTypeCd == "PrT_I" ||
                      chronology.PriceTypeCd == "PrT_E" ||
                      chronology.PriceTypeCd == "PrT_F"
                        ? _c(
                            "div",
                            [
                              chronology.ProductLinePrices.length == 0
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row justify-content-center",
                                      },
                                      [
                                        _c("i", [
                                          _vm._v(
                                            "No Pricing has been provided"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._l(
                                chronology.ProductLinePrices,
                                function (price, priceIndex) {
                                  return _c("div", { key: priceIndex }, [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPriceString(
                                              chronology,
                                              price
                                            )
                                          ) +
                                          " "
                                      ),
                                      chronology.IsIndicativePricingInd == true
                                        ? _c(
                                            "button",
                                            {
                                              directives: [
                                                {
                                                  name: "tippy",
                                                  rawName: "v-tippy",
                                                  value: {
                                                    position: "bottom",
                                                    arrow: true,
                                                  },
                                                  expression:
                                                    "{ position: 'bottom', arrow: true }",
                                                },
                                              ],
                                              staticStyle: {
                                                border: "none",
                                                "background-color": "inherit",
                                                color: "grey",
                                              },
                                              attrs: {
                                                type: "button",
                                                title:
                                                  _vm.getTooltipsTextByCode(11),
                                              },
                                            },
                                            [
                                              _c("mdb-icon", {
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                                style: {
                                                  color: "orange",
                                                },
                                                attrs: {
                                                  far: "",
                                                  icon: "exclamation-circle",
                                                  size: "1x",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ])
                                }
                              ),
                            ],
                            2
                          )
                        : _c("div", [_vm._v(" " + _vm._s(/* TODO )*/) + " ")]),
                    ]),
                  ]),
                  _c("div", { staticClass: "float-right ml-auto my-auto" }, [
                    _c(
                      "button",
                      {
                        staticClass: "p-2",
                        staticStyle: {
                          border: "none",
                          "background-color": "inherit",
                        },
                        attrs: {
                          type: "button",
                          disabled: _vm.showEditPane == true,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onEdit(index)
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          attrs: {
                            color: _vm.showEditPane ? "lightgrey" : "primary",
                            icon: "edit",
                            size: "2x",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "p-2",
                        staticStyle: {
                          border: "none",
                          "background-color": "inherit",
                        },
                        attrs: {
                          type: "button",
                          disabled: _vm.showEditPane == true,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onDelete(index)
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          attrs: {
                            color: _vm.showEditPane ? "lightgrey" : "primary",
                            far: "",
                            icon: "trash-alt",
                            size: "2x",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }
          ),
          _vm.showEditPane == false
            ? _c("div", { staticClass: "mt-2" }, [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "mr-2 ml-auto command-button",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onAdd()
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "mr-1",
                          attrs: { icon: "plus" },
                        }),
                        _vm._v("Add "),
                      ],
                      1
                    ),
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "command-button",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onClose()
                          },
                        },
                      },
                      [_vm._v("Close")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.commercialProductLine.Chronology.length > 0 &&
          _vm.showEditPane == true
            ? [_c("hr")]
            : _vm._e(),
          _c("expansion-region", { attrs: { toggle: _vm.showEditPane } }, [
            _vm.currentItemIdx == -1
              ? _c("h5", { ref: "ErrorMessages" }, [_vm._v("Add Item")])
              : _c("h5", { ref: "ErrorMessages" }, [_vm._v("Edit Item")]),
            _vm.formValidationMessages.length > 0
              ? _c(
                  "div",
                  { staticClass: "errorbox" },
                  _vm._l(_vm.formValidationMessages, function (m) {
                    return _c("li", { key: m, staticClass: "error-msg" }, [
                      _vm._v(" " + _vm._s(m) + " "),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                  [
                    _vm._l(
                      _vm.getMarketCategories(),
                      function (category, index) {
                        return [
                          _c("awgt-input", {
                            key: index,
                            attrs: {
                              type: "checkbox",
                              id: "is" + category.Name,
                              name: "is" + category.Name,
                              label: category.Name,
                            },
                            model: {
                              value: _vm.marketCategories[index],
                              callback: function ($$v) {
                                _vm.$set(_vm.marketCategories, index, $$v)
                              },
                              expression: "marketCategories[index]",
                            },
                          }),
                        ]
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                  [
                    _c("awgt-input", {
                      staticClass: "flex-grow-1",
                      attrs: {
                        counterMaxValue: 100,
                        maxlength: "100",
                        counter: "",
                        label: "Name",
                        bg: "",
                        required: "",
                      },
                      model: {
                        value: _vm.commercialProductLineChronology.Name,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.commercialProductLineChronology,
                            "Name",
                            $$v
                          )
                        },
                        expression: "commercialProductLineChronology.Name",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "mb-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1",
                    },
                    [
                      _c("mdb-icon", {
                        attrs: {
                          icon: "clipboard",
                          far: "",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("div", { staticClass: "ml-2" }, [
                        _vm._v("Description"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mdb-wysiwyg", {
                        staticStyle: { height: "150px" },
                        model: {
                          value:
                            _vm.commercialProductLineChronology.Description,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.commercialProductLineChronology,
                              "Description",
                              $$v
                            )
                          },
                          expression:
                            "commercialProductLineChronology.Description",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "mb-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-row flex-nowrap flex-grow-1 align-items-center mb-1",
                    },
                    [
                      _c("mdb-icon", {
                        attrs: {
                          icon: "clipboard",
                          far: "",
                          color: "primary",
                          size: "2x",
                        },
                      }),
                      _c("div", { staticClass: "ml-2" }, [
                        _vm._v("Purchasing Reminder"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mdb-wysiwyg", {
                        staticStyle: { height: "150px" },
                        model: {
                          value:
                            _vm.commercialProductLineChronology
                              .PurchasingReminder,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.commercialProductLineChronology,
                              "PurchasingReminder",
                              $$v
                            )
                          },
                          expression:
                            "commercialProductLineChronology.PurchasingReminder",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "mdb-form-inline",
                  { staticClass: "flex-wrap border px-2 py-3 mb-2" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                      [
                        _c("span", { staticStyle: { width: "100px" } }, [
                          _vm._v("Price Type:"),
                        ]),
                        _c("awgt-input", {
                          attrs: {
                            id: "price_GSTIn",
                            type: "radio",
                            name: "PriceTypeGroup",
                            label: "GST Incl.",
                            radioValue: "PrT_I",
                          },
                          model: {
                            value:
                              _vm.commercialProductLineChronology.PriceTypeCd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.commercialProductLineChronology,
                                "PriceTypeCd",
                                $$v
                              )
                            },
                            expression:
                              "commercialProductLineChronology.PriceTypeCd",
                          },
                        }),
                        _c("awgt-input", {
                          attrs: {
                            id: "price_GSTEx",
                            type: "radio",
                            name: "PriceTypeGroup",
                            label: "GST Excl.",
                            radioValue: "PrT_E",
                          },
                          model: {
                            value:
                              _vm.commercialProductLineChronology.PriceTypeCd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.commercialProductLineChronology,
                                "PriceTypeCd",
                                $$v
                              )
                            },
                            expression:
                              "commercialProductLineChronology.PriceTypeCd",
                          },
                        }),
                        _c("awgt-input", {
                          attrs: {
                            id: "price_GSTFr",
                            type: "radio",
                            name: "PriceTypeGroup",
                            label: "GST Free",
                            radioValue: "PrT_F",
                          },
                          model: {
                            value:
                              _vm.commercialProductLineChronology.PriceTypeCd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.commercialProductLineChronology,
                                "PriceTypeCd",
                                $$v
                              )
                            },
                            expression:
                              "commercialProductLineChronology.PriceTypeCd",
                          },
                        }),
                        _c("awgt-input", {
                          attrs: {
                            id: "price_GSTNo",
                            type: "radio",
                            name: "PriceTypeGroup",
                            label: "No Charge",
                            radioValue: "PrT_Z",
                          },
                          model: {
                            value:
                              _vm.commercialProductLineChronology.PriceTypeCd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.commercialProductLineChronology,
                                "PriceTypeCd",
                                $$v
                              )
                            },
                            expression:
                              "commercialProductLineChronology.PriceTypeCd",
                          },
                        }),
                        _c("awgt-input", {
                          attrs: {
                            id: "price_Quote",
                            type: "radio",
                            name: "PriceTypeGroup",
                            label: "By Quote",
                            radioValue: "PrT_Q",
                          },
                          model: {
                            value:
                              _vm.commercialProductLineChronology.PriceTypeCd,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.commercialProductLineChronology,
                                "PriceTypeCd",
                                $$v
                              )
                            },
                            expression:
                              "commercialProductLineChronology.PriceTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.commercialProductLineChronology.PriceTypeCd != "PrT_Q" &&
                _vm.commercialProductLineChronology.PriceTypeCd != "PrT_Z"
                  ? _c(
                      "div",
                      { staticClass: "flex-wrap border px-2 py-3 my-2" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                          },
                          [
                            _vm.commercialProductLineChronology
                              .ProductLinePrices.length == 0
                              ? _c("div", [
                                  _vm._v(
                                    " Pricing for this product line is... "
                                  ),
                                ])
                              : _vm._e(),
                            _vm.commercialProductLineChronology
                              .ProductLinePrices.length == 0
                              ? _c(
                                  "awgt-std-button",
                                  {
                                    staticClass: "ml-auto command-button",
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        _vm.arePricingScaleTypesDisabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onAddPricingScaleType()
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "mr-1",
                                      attrs: { icon: "plus" },
                                    }),
                                    _vm._v("Add "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.commercialProductLineChronology.ProductLinePrices
                          .length == 0
                          ? [
                              _vm.commercialProductLineChronology
                                .PricingScaleTypes.length == 0
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row justify-content-center",
                                      },
                                      [
                                        _c("i", [
                                          _vm._v(
                                            "Press Add to specify how pricing is determined"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : _vm._l(
                                    _vm.commercialProductLineChronology
                                      .PricingScaleTypes,
                                    function (pricingScaleType, index) {
                                      return _c("div", { key: index }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex flex-row flex-grow-1 align-items-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                                              },
                                              [
                                                _c("awgt-std-dropdown", {
                                                  staticClass:
                                                    "flex-grow-1 mr-2",
                                                  attrs: {
                                                    items:
                                                      _vm.getQuantityUnitPurposes(),
                                                    itemValueProperty: "Code",
                                                    itemTextProperty: "Name",
                                                    label: "Purpose",
                                                    bg: "",
                                                    disabled:
                                                      _vm.arePricingScaleTypesDisabled,
                                                  },
                                                  model: {
                                                    value:
                                                      pricingScaleType.QuantityUnitPurposeCd,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        pricingScaleType,
                                                        "QuantityUnitPurposeCd",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pricingScaleType.QuantityUnitPurposeCd",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                                              },
                                              [
                                                _c("awgt-std-dropdown", {
                                                  staticClass:
                                                    "flex-grow-1 mr-2",
                                                  attrs: {
                                                    items:
                                                      _vm.getFilteredQuantityUnits(
                                                        _vm
                                                          .commercialProductLineChronology
                                                          .PricingScaleTypes,
                                                        pricingScaleType.QuantityUnitCd
                                                      ),
                                                    itemValueProperty: "Code",
                                                    itemTextProperty: "Name",
                                                    label: "Quantity Unit",
                                                    bg: "",
                                                    disabled:
                                                      _vm.arePricingScaleTypesDisabled,
                                                  },
                                                  on: {
                                                    "get-value": function (
                                                      $event
                                                    ) {
                                                      return _vm.onPricingScaleTypeQuantityUnitSelected(
                                                        pricingScaleType,
                                                        _vm.commercialProductLineChronology
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      pricingScaleType.QuantityUnitCd,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        pricingScaleType,
                                                        "QuantityUnitCd",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pricingScaleType.QuantityUnitCd",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                  },
                                                  [_vm._v("with scaling")]
                                                ),
                                                _c("awgt-std-dropdown", {
                                                  staticClass: "flex-grow-1",
                                                  attrs: {
                                                    items:
                                                      _vm.getFilteredPricingScales(
                                                        pricingScaleType.QuantityUnitPurposeCd
                                                      ),
                                                    itemValueProperty: "Code",
                                                    itemTextProperty: "Name",
                                                    label: "Pricing Scale",
                                                    bg: "",
                                                    disabled:
                                                      _vm.arePricingScaleTypesDisabled,
                                                  },
                                                  model: {
                                                    value:
                                                      pricingScaleType.PricingScaleCd,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        pricingScaleType,
                                                        "PricingScaleCd",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "pricingScaleType.PricingScaleCd",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "awgt-std-button",
                                              {
                                                staticClass:
                                                  "command-button ml-2",
                                                staticStyle: {
                                                  "min-width": "63px",
                                                  "margin-left": "auto",
                                                },
                                                attrs: {
                                                  type: "button",
                                                  disabled:
                                                    _vm.arePricingScaleTypesDisabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onDeletePricingScaleType(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("mdb-icon", {
                                                  staticClass: "mr-1",
                                                  attrs: { icon: "trash-alt" },
                                                }),
                                                _vm._v("Delete"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        pricingScaleType.QuantityUnitCd ==
                                        "QU_I"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex flex-row flex-wrap flex-grow-1 align-items-center",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      'where "item" is named'
                                                    ),
                                                  ]
                                                ),
                                                _c("awgt-input", {
                                                  staticClass:
                                                    "flex-grow-1 mr-2",
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                  attrs: {
                                                    counterMaxValue: 15,
                                                    maxlength: "15",
                                                    counter: "",
                                                    label: "Alias",
                                                    bg: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .commercialProductLineChronology
                                                        .SingularItemAlias,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.commercialProductLineChronology,
                                                        "SingularItemAlias",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                      commercialProductLineChronology.SingularItemAlias\n                    ",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _vm._v(
                                                      'and "items" is named'
                                                    ),
                                                  ]
                                                ),
                                                _c("awgt-input", {
                                                  staticClass: "flex-grow-1",
                                                  staticStyle: {
                                                    width: "100px",
                                                  },
                                                  attrs: {
                                                    counterMaxValue: 15,
                                                    maxlength: "15",
                                                    counter: "",
                                                    label: "Plural Alias",
                                                    bg: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .commercialProductLineChronology
                                                        .PluralItemAlias,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.commercialProductLineChronology,
                                                        "PluralItemAlias",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "commercialProductLineChronology.PluralItemAlias",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    }
                                  ),
                            ]
                          : [
                              _vm.hasPerPricing(
                                _vm.commercialProductLineChronology
                                  .PricingScaleTypes
                              ) == true
                                ? _c("div", [
                                    _vm._v(
                                      " Pricing is " +
                                        _vm._s(
                                          _vm.getPerQuantityUnitQualifier(
                                            _vm.commercialProductLineChronology
                                          )
                                        ) +
                                        ". "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.hasAPricingScale(
                                _vm.commercialProductLineChronology
                                  .PricingScaleTypes
                              ) == true
                                ? _c("div", [
                                    _vm._v(
                                      " Pricing is scaled " +
                                        _vm._s(
                                          _vm.getScaledByQualifier(
                                            _vm.commercialProductLineChronology
                                          )
                                        ) +
                                        ". "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.commercialProductLineChronology.PriceTypeCd == "PrT_Q" ||
                _vm.commercialProductLineChronology.PriceTypeCd == "PrT_Z"
                  ? [
                      _c(
                        "div",
                        { staticStyle: { border: "1px solid #dee2e6" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-wrap flex-grow-1 align-items-center mx-2 py-2",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Pricing: " +
                                    _vm._s(
                                      _vm.getPriceTypeStr(
                                        _vm.commercialProductLineChronology
                                          .PriceTypeCd
                                      )
                                    )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  : [
                      _vm.commercialProductLineChronology.PricingScaleTypes
                        .length > 0
                        ? [
                            _c(
                              "div",
                              { staticStyle: { border: "1px solid #dee2e6" } },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("commercial-product-line-price-list", {
                                      model: {
                                        value:
                                          _vm.commercialProductLineChronology,
                                        callback: function ($$v) {
                                          _vm.commercialProductLineChronology =
                                            $$v
                                        },
                                        expression:
                                          "commercialProductLineChronology",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "mt-2 ml-2" }, [
                                  _vm._v(
                                    " Minimum Total Price " +
                                      _vm._s(
                                        _vm.getPriceTypeStr(
                                          _vm.commercialProductLineChronology
                                            .PriceTypeCd
                                        )
                                      ) +
                                      ": "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-row align-items-center ml-2",
                                  },
                                  [
                                    _c("mdb-icon", {
                                      staticClass: "ml-1",
                                      staticStyle: { width: "28px" },
                                      attrs: {
                                        icon: "dollar-sign",
                                        color: "primary",
                                        size: "2x",
                                      },
                                    }),
                                    _c("awgt-input", {
                                      directives: [
                                        {
                                          name: "numeric",
                                          rawName: "v-numeric",
                                          value: {
                                            precision: 6,
                                            scale: 2,
                                            posOnly: false,
                                          },
                                          expression:
                                            "{\n                    precision: 6,\n                    scale: 2,\n                    posOnly: false,\n                  }",
                                        },
                                      ],
                                      staticClass: "ml-1",
                                      staticStyle: { width: "220px" },
                                      attrs: {
                                        maxlength: "7",
                                        label: "Minimum Total Price Amount",
                                        bg: "",
                                        type: "text",
                                        tooltip:
                                          "The minimum product price charged regardless of the quantity purchased",
                                      },
                                      model: {
                                        value:
                                          _vm.commercialProductLineChronology
                                            .MinTotalPriceAmt,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.commercialProductLineChronology,
                                            "MinTotalPriceAmt",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "\n                    commercialProductLineChronology.MinTotalPriceAmt\n                  ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "-30px" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "ml-2",
                                        staticStyle: {
                                          position: "relative",
                                          top: "40px",
                                        },
                                      },
                                      [_vm._v(" Surcharges & Discounts: ")]
                                    ),
                                    _c("product-price-adjustments", {
                                      staticClass: "mx-2 my-2",
                                      model: {
                                        value:
                                          _vm.commercialProductLineChronology
                                            .CommercialProductLinePriceAdjustments,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.commercialProductLineChronology,
                                            "CommercialProductLinePriceAdjustments",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                    commercialProductLineChronology.CommercialProductLinePriceAdjustments\n                  ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                _c(
                  "div",
                  { staticClass: "d-flex flex-row flex-nowrap flex-grow-1" },
                  [
                    _c("mdb-icon", {
                      staticClass: "mt-3",
                      staticStyle: { width: "28px" },
                      attrs: {
                        icon: "calendar-alt",
                        far: "",
                        color: "primary",
                        size: "2x",
                      },
                    }),
                    _c("asoftDatePicker", {
                      staticClass: "ml-2",
                      attrs: {
                        label: "Valid From",
                        autoHide: "",
                        displayDateFormat:
                          _vm.$constants.DATETIMEFORMAT_Presentation,
                        dataDateFormat: _vm.$constants.DATETIMEFORMAT_Json,
                        setTime: "00:00:00.000",
                        bg: "",
                      },
                      model: {
                        value: _vm.commercialProductLineChronology.ActiveFromDt,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.commercialProductLineChronology,
                            "ActiveFromDt",
                            $$v
                          )
                        },
                        expression:
                          "commercialProductLineChronology.ActiveFromDt",
                      },
                    }),
                    _c("asoftDatePicker", {
                      staticClass: "ml-2",
                      attrs: {
                        label: "Valid To",
                        autoHide: "",
                        displayDateFormat:
                          _vm.$constants.DATETIMEFORMAT_Presentation,
                        dataDateFormat: _vm.$constants.DATETIMEFORMAT_Json,
                        setTime: "23:59:59.000",
                        bg: "",
                      },
                      on: {
                        change:
                          _vm.doCommercialProductLineChronologyValidToDate_input,
                      },
                      model: {
                        value: _vm.commercialProductLineChronology.ActiveToDt,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.commercialProductLineChronology,
                            "ActiveToDt",
                            $$v
                          )
                        },
                        expression:
                          "commercialProductLineChronology.ActiveToDt",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-row flex-wrap flex-grow-1" },
                  [
                    _c("awgt-input", {
                      staticClass: "mt-1 pl0-important",
                      staticStyle: { "margin-left": "18px" },
                      attrs: {
                        type: "checkbox",
                        id: "DisplayBoundariesOnly",
                        name: "DisplayBoundariesOnly",
                        label: "Display Boundaries Only?",
                      },
                      model: {
                        value:
                          _vm.commercialProductLineChronology
                            .DisplayBoundariesOnly,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.commercialProductLineChronology,
                            "DisplayBoundariesOnly",
                            $$v
                          )
                        },
                        expression:
                          "commercialProductLineChronology.DisplayBoundariesOnly",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row flex-nowrap align-items-center",
                  },
                  [
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "ml-auto mr-2 command-button",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onSave()
                          },
                        },
                      },
                      [_vm._v("Save")]
                    ),
                    _c(
                      "awgt-std-button",
                      {
                        staticClass: "mr-0 command-button",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.onCancel()
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
          _vm.showDeletionConfirmationModal == true
            ? _c(
                "mdb-modal",
                {
                  attrs: {
                    size: "sm",
                    show: _vm.showDeletionConfirmationModal,
                  },
                },
                [
                  _c(
                    "mdb-modal-header",
                    [_c("mdb-modal-title", [_vm._v("Confirm Delete")])],
                    1
                  ),
                  _c("mdb-modal-body", [
                    _vm._v('Press "Confirm" to delete the pricing component. '),
                    _vm.commercialProductLine.Chronology.length == 1
                      ? _c("p", [
                          _c("br"),
                          _vm._v(
                            " This will also delete this component and close the edit dialog. "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "mdb-modal-footer",
                    [
                      _c(
                        "awgt-std-button",
                        {
                          staticClass: "command-button mx-2",
                          attrs: { type: "button" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onConfirmDeletePricingComponent.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("mdb-icon", {
                            staticClass: "mr-1",
                            attrs: { icon: "check" },
                          }),
                          _vm._v("Confirm "),
                        ],
                        1
                      ),
                      _c(
                        "awgt-std-button",
                        {
                          staticClass: "command-button mx-2",
                          attrs: { type: "button" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onCancelDeletePricingComponent.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("mdb-icon", {
                            staticClass: "mr-1",
                            attrs: { icon: "times" },
                          }),
                          _vm._v("Cancel"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }