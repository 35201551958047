import { render, staticRenderFns } from "./asoftFieldFormatter.vue?vue&type=template&id=0e40f4d8&"
import script from "./asoftFieldFormatter.vue?vue&type=script&lang=js&"
export * from "./asoftFieldFormatter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Workarea\\Atom Software\\Clients\\Away We Go Tours\\src\\awgt-client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0e40f4d8')) {
      api.createRecord('0e40f4d8', component.options)
    } else {
      api.reload('0e40f4d8', component.options)
    }
    module.hot.accept("./asoftFieldFormatter.vue?vue&type=template&id=0e40f4d8&", function () {
      api.rerender('0e40f4d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AtomSoftware/asoftFieldFormatter.vue"
export default component.exports