<template>
  <div>
    <div class="d-flex flex-row flex-nowrap flex-grow-1 align-items-center">
      <slot name="default"></slot>
      <awgt-std-button
        type="button"
        class="command-button"
        style="min-width: 100px"
        @click="AddGroup()"
      >
        <mdb-icon icon="edit" class="mr-1" />Add
      </awgt-std-button>
    </div>
    <div v-if="chosenGroups.length > 0" class="pl-3 py-2">
      This product is in the following product groups:
      <ul v-for="(group, index) in chosenGroups" :key="index">
        <li>
          <div class="d-flex flex-row justify-content-between">
            <div class="d-flex flex-column">
              <div>
                {{ group.Name }}
              </div>
              <div v-if="group.OtherProductsInGroup != undefined">
                Other products in this group are:
                <ul
                  v-for="(inGroup, index2) in group.OtherProductsInGroup"
                  :key="index2"
                >
                  <li>{{ inGroup.Name }}</li>
                </ul>
              </div>
            </div>
            <div>
              <!-- Hiding the button looks weird so using disabled -->
              <awgt-std-button
                type="button"
                class="command-button"
                style="min-width: 100px"
                @click="DeleteGroup(index)"
              >
                <mdb-icon icon="trash-alt" class="mr-1" />Delete
              </awgt-std-button>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <mdb-modal :show="showEditor">
      <mdb-modal-header>
        <h1 class="text-center">Reseller Product Groups</h1>
      </mdb-modal-header>
      <mdb-modal-body>
        <div
          v-if="groupAlreadyChosenErrorVisible == true"
          class="mb-2 text-danger"
        >
          <span>This group has already been chosen.</span>
        </div>
        <div
          v-if="groupAlreadyExistingErrorVisible == true"
          class="mb-2 text-danger"
        >
          <span
            >This is an existing group, please select it from the list.</span
          >
        </div>
        <div v-if="groupNameEmptyVisible == true" class="mb-2 text-danger">
          <span>Please supply a name for the group.</span>
        </div>
        <span class="mr-5" style="width: 120px">Group Type</span>
        <awgt-input
          id="groupType_Existing"
          v-model="groupType"
          type="radio"
          name="GroupTypeGroup"
          label="Existing"
          radioValue="existing"
        />
        <awgt-input
          id="groupType_New"
          v-model="groupType"
          type="radio"
          name="GroupTypeGroup"
          label="New"
          radioValue="new"
        />

        <div v-if="this.groupType == 'existing'">
          <awgt-std-dropdown
            v-model="existingGroup"
            :items="resellerSupplierGroups"
            itemValueProperty="Name"
            itemTextProperty="Name"
            label="Group"
            bg
            style="width: 450px"
            :tooltip="getTooltipsTextByCode(52)"
          />
          <div v-if="existingGroup != null">
            Other products in this group are:
            <ul
              v-for="(productInGroup, index) in getOtherProductsInGroup(
                existingGroup
              )"
              :key="index"
            >
              <li>{{ productInGroup.Name }}</li>
            </ul>
          </div>
        </div>
        <awgt-input v-else bg label="Title" v-model="newGroup" type="text" />
      </mdb-modal-body>
      <mdb-modal-footer>
        <awgt-std-button
          class="command-button mr-2"
          type="button"
          @click="SaveAndCloseEditor()"
        >
          Save
        </awgt-std-button>
        <awgt-std-button
          type="button"
          class="command-button mr-2"
          @click="CloseEditor()"
        >
          Cancel
        </awgt-std-button>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<style>
.textarea-input textarea {
  resize: vertical !important;
}
</style>

<script>
import awgtInput from "@/components/AWGT/AwgtInput";
import { mapGetters } from "vuex";
import {
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";
import AwgtStdDropdown from "@/components/AWGT/AwgtStdDropdown";

export default {
  components: {
    awgtInput,
    mdbIcon,
    AwgtStdButton,
    AwgtStdDropdown,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
  },

  data() {
    return {
      chosenGroups: [],
      groupType: "existing",
      existingGroup: "",
      newGroup: "",
      chosenGroup: {},
      chosenGroup2: "",
      showEditor: false,
      groupAlreadyChosenErrorVisible: false,
      groupAlreadyExistingErrorVisible: false,
      groupNameEmptyVisible: false,
    };
  },

  props: {
    resellerSupplierGroups: {
      type: Array,
    },
    value: {
      type: Array,
    },
  },

  computed: {
    ...mapGetters(["getTooltipsTextByCode"]),
  },

  methods: {
    AddGroup() {
      this.newGroup = "";
      this.existingGroup = "";
      this.showEditor = true;
    },

    getOtherProductsInGroup(existingGroup) {
      let group = this.resellerSupplierGroups.find(
        (rsg) => rsg.Name == existingGroup
      );
      if (group == undefined) return null;
      else return group.OtherProductsInGroup;
    },

    SaveAndCloseEditor() {
      //New Item
      this.groupAlreadyChosenErrorVisible = false;
      this.groupAlreadyExistingErrorVisible = false;
      this.groupNameEmptyVisible = false;
      this.chosenGroup = {};
      if (this.groupType == "new") {
        if (this.newGroup.trim().length == 0) {
          this.groupNameEmptyVisible = true;
          return;
        } else if (this.chosenGroups.some((x) => x.Name == this.newGroup)) {
          this.groupAlreadyChosenErrorVisible = true;
          return;
        } else if (
          this.resellerSupplierGroups.some((x) => x.Name == this.newGroup)
        ) {
          this.groupAlreadyExistingErrorVisible = true;
          return;
        } else this.$set(this.chosenGroup, "Name", this.newGroup);
      } else this.$set(this.chosenGroup, "Name", this.existingGroup);
      this.chosenGroups.push(this.chosenGroup);
      this.CloseEditor();
    },

    CloseEditor() {
      this.showEditor = false;
    },

    DeleteGroup(idx) {
      this.chosenGroups.splice(idx, 1);
    },
  },

  watch: {
    value(to) {
      this.chosenGroups = to;
    },

    chosenGroups(to) {
      this.$emit("input", to);
    },
  },

  mounted() {},
};
</script>
