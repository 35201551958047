<!--
  This control is a wrapper around the mdbInput control.

  The control displays a displayedValue and returns to the user a rawValue. In
  most cases the displayedValue and rawValue are the same but when a mask is
  used the displayedValue may include a visible mask whereas the rawValue will
  be the value without the mask.
  
  When using a mask the control needs to be careful to display the displayedValue
  but return the rawValue. This gets a slightly tricky as the control allows the
  use of v-model which passes back changes to the raw value back into the control,
  the control then needs to be careful that it doesn't display the raw value. It
  does this by internally storing the raw value and comparing that to the value
  being passed in, if they haven't changed then it ignores value passed in. It 
  also handles it by specially handling any changes in value by passing them to
  a setInitialValue method that reinitialises the mask with a new value.

  The control also needs to handle input events carefully. If the mask is not
  set (non blank) then it applies the standard input events to the input control.
  If the mask is set then it applies special mask-input events from the asoftMask
  directive to the input control.
  -->

<template>
  <div style="position: relative">
    <!--
      Checked and CheckboxValue have been removed as they do not work in the
      MDB control, use v-model instead.
    -->
    <mdb-input
      ref="mdbInput"
      :value="displayedValue"
      :active="active"
      :tag="tag"
      :basic="basic"
      :bg="bg"
      :disabled="disabled"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      :required="required"
      :type="type"
      :autofocus="autofocus"
      :validation="validation"
      :customValidation="customValidation"
      :isValid="isValid"
      :validFeedback="validFeedback"
      :invalidFeedback="invalidFeedback"
      :outline="outline"
      :small="small"
      :navInput="navInput"
      :waves="waves"
      :wrapperClass="wrapperClass"
      :noWrapper="noWrapper"
      :inputClass="inputClass"
      :maxlength="maxlength"
      :readOnly="readOnly"
      :autocomplete="autocomplete"
      :counterMaxValue="counterMaxValue"
      :counter="counter"
      :iconClass="iconClass"
      :far="far"
      :regular="regular"
      :fal="fal"
      :light="light"
      :fab="fab"
      :brands="brands"
      :label="label"
      :labelColor="labelColor"
      :labelClass="labelClass"
      :min="min"
      :max="max"
      :step="step"
      :gap="gap"
      :radioValue="String(radioValue)"
      :filled="filled"
      :rows="rows"
      :ariaLabel="ariaLabel"
      :ariaDescribedBy="ariaDescribedBy"
      :ariaLabelledBy="ariaLabelledBy"
      :ariaMultiselectable="ariaMultiselectable"
      :ariaDisabled="ariaDisabled"
      :ariaRequired="ariaRequired"
      :ariaHaspopup="ariaHaspopup"
      :role="role"
      @change="onChange"
      @get-default-value="onGetDefaultValue"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @input="onInput"
      v-mask="{ mask: mask, value: initValue }"
    />
    <button
      v-if="tooltip.length > 0"
      type="button"
      :class="{
        tipsButtonInputFiled: isInputFiled,
        tipsButtonRadionButton: isRadioButton,
      }"
      :title="tooltip"
      v-tippy="{ position: 'bottom', arrow: true }"
      style="position: absolute"
      :style="{ top: type == 'checkbox' || type == 'radio' ? '0px' : 'auto' }"
    >
      <mdb-icon
        :icon="getTooltipIconName(tooltipType)"
        fas
        style="cursor: pointer"
        :style="{ color: tooltipType == 'warning' ? '#ffcc00' : 'grey' }"
        size="1x"
      />
    </button>
  </div>
</template>

<script>
import { mdbInput, mdbIcon } from "mdbvue";
import mask from "./asoftMask";

export default {
  data() {
    return {
      initValue: "",
      displayedValue: "",
      maskedValue: "",
      usingMaskInd: false,
      isInputFiled: false,
      isRadioButton: false,
    };
  },
  watch: {
    //Note: if you have issues then this watch might need to be immediate.
    value(to) {
      //Possibly should be "displayedValue"
      if (this.usingMaskInd == true && to !== this.maskedValue)
        this.setInitialValue(to);
      else if (this.usingMaskInd == false && to !== this.displayedValue)
        this.setInitialValue(to);
    },

    mask(to, from) {
      if (to != from) {
        this.usingMaskInd = to.length > 0;
        this.bindMaskInputListener();
      }
    },
  },

  props: {
    tooltip: {
      type: String,
      default: "",
    },

    tooltipType: {
      type: String,
      default: "information",
    },

    value: {
      type: [String, Number, Boolean],
    },

    type: {
      type: String,
      default: "text",
      validator: function (value) {
        // The value must match one of these strings
        return (
          [
            "text",
            "checkbox",
            "email",
            "file",
            "image",
            "number",
            "password",
            "tel",
            "radio",
            "textarea",
          ].indexOf(value) !== -1
        );
      },
    },

    active: {
      type: Boolean,
      default: false,
    },

    tag: {
      type: String,
      default: "input",
    },

    basic: {
      type: Boolean,
      default: false,
    },

    bg: { type: Boolean, default: false },
    disabled: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
    },

    name: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    required: {
      type: Boolean,
      default: false,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    validation: {
      type: Boolean,
      default: false,
    },

    customValidation: {
      type: Boolean,
      default: false,
    },

    isValid: {
      type: Boolean,
      default: false,
    },

    validFeedback: {
      type: [String, Boolean],
      default: false,
    },

    invalidFeedback: {
      type: [String, Boolean],
      default: false,
    },

    outline: {
      type: Boolean,
    },

    small: {
      type: String,
    },

    size: {
      type: String,
    },

    navInput: {
      type: Boolean,
      default: false,
    },

    waves: {
      type: Boolean,
      default: false,
    },

    wrapperClass: {
      type: String,
    },

    noWrapper: {
      type: Boolean,
      default: false,
    },

    inputClass: {
      type: String,
    },

    maxlength: {
      type: [String, Number],
    },

    readOnly: {
      type: Boolean,
    },

    autocomplete: {
      type: String,
    },

    counter: {
      type: Boolean,
    },

    counterMaxValue: {
      type: Number,
      default: 10,
    },

    icon: {
      type: String,
    },

    iconClass: {
      type: String,
    },

    far: {
      type: String,
    },

    regular: {
      type: String,
    },

    fal: {
      type: String,
    },

    light: {
      type: String,
    },

    fab: {
      type: String,
    },

    brands: {
      type: String,
    },

    label: {
      type: String,
    },

    labelColor: {
      type: String,
    },

    labelClass: {
      type: String,
    },

    min: {
      type: Number,
    },

    max: {
      type: Number,
    },

    step: {
      type: Number,
    },

    gap: {
      type: Boolean,
      default: false,
    },

    radioValue: {
      //Allows string, number and true/false values for radio buttons
      type: [String, Boolean, Number],
    },

    filled: {
      type: Boolean,
    },

    rows: {
      type: Number,
    },

    ariaLabel: {
      type: String,
    },

    ariaDescribedBy: {
      type: String,
    },

    ariaLabelledBy: {
      type: String,
    },

    ariaMultiselectable: {
      type: Boolean,
    },

    ariaDisabled: {
      type: Boolean,
    },

    ariaRequired: {
      type: Boolean,
    },

    ariaHaspopup: {
      type: Boolean,
    },

    role: {
      type: String,
    },

    mask: {
      type: String,
      default: "",
    },

    maskedValueType: {
      type: String,
      default: "raw",
      validator: function (value) {
        //maskedValueType must be 'raw' or 'formatted'
        return ["raw", "formatted"].indexOf(value) != -1;
      },
    },
  },

  components: {
    mdbInput,
    mdbIcon,
  },

  computed: {
    getTooltipIconName() {
      return (tooltipType) => {
        switch (tooltipType) {
          case "information":
            return "info-circle";
          case "question":
            return "question-circle";
          case "warning":
            return "exclamation-triangle";
          default:
            return "info-circle";
        }
      };
    },
  },

  methods: {
    onChange(e) {
      this.$emit("change", e);
    },
    onGetDefaultValue(e) {
      this.$emit("get-default-value", e);
    },
    onMaskInput(e) {
      if (e) {
        if (this.maskedValueType == "raw") this.maskedValue = e.raw;
        else this.maskedValue = e.formatted;
        this.$emit("input", this.maskedValue);
      }
    },
    onInput(e) {
      if (this.type == "radio" && typeof this.radioValue == "boolean")
        //Convert to boolean
        this.$emit("input", e === "true");
      else this.$emit("input", typeof e == "string" ? e.trim() : e);
    },
    bindMaskInputListener() {
      if (this.usingMaskInd) {
        this.$on("mask-input", this.onMaskInput);
        if (this.$refs.mdbInput) this.$refs.mdbInput.$off("input");
      } else {
        this.$off("mask-input");
        if (this.$refs.mdbInput) this.$refs.mdbInput.$on("input", this.onInput);
      }
    },
    setInitialValue(val) {
      if (this.usingMaskInd) this.initValue = val;
      else this.displayedValue = this.type == "radio" ? String(val) : val;
    },
  },

  created() {
    this.usingMaskInd = this.mask.length > 0;
  },

  mounted() {
    this.bindMaskInputListener();
    this.setInitialValue(this.value);
    switch (this.type) {
      case "input":
        this.isInputFiled = true;
        break;
      case "radio":
        this.isRadioButton = true;
        break;
      default:
        this.isInputFiled = true;
        break;
    }
  },

  directives: {
    mask: mask,
  },
};
</script>
<style scoped>
.tipsButtonInputFiled {
  border: none;
  background-color: inherit;
  color: grey;
  float: right;
  position: absolute;
  bottom: 22px;
  right: 10px;
}

.tipsButtonRadionButton {
  border: none;
  background-color: inherit;
  color: grey;
  float: right;
  position: absolute;
  bottom: -1px;
  right: -30px;
}
</style>
