import { render, staticRenderFns } from "./AdditionalCharges.vue?vue&type=template&id=02b3ee18&"
import script from "./AdditionalCharges.vue?vue&type=script&lang=js&"
export * from "./AdditionalCharges.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Workarea\\Atom Software\\Clients\\Away We Go Tours\\src\\awgt-client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02b3ee18')) {
      api.createRecord('02b3ee18', component.options)
    } else {
      api.reload('02b3ee18', component.options)
    }
    module.hot.accept("./AdditionalCharges.vue?vue&type=template&id=02b3ee18&", function () {
      api.rerender('02b3ee18', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AdditionalCharges.vue"
export default component.exports