<template>
  <!--
        Related Product Selection Dialog
    -->
  <!-- The v-on below allows you to run a method when the dialog becomes visible -->
  <div v-on="onVisible()">
    <mdb-modal-header>
      <mdb-modal-title>Related Product Selection</mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="px-2">
      <ul class="border">
        <li
          v-for="(item, index) in supplierProductsSoldSeparately"
          :key="index"
          class="d-flex"
        >
          <awgt-input
            :id="'supplierProduct_' + item.reference"
            type="checkbox"
            :label="item.name"
            v-model="item.checked"
          ></awgt-input>
        </li>
      </ul>
    </mdb-modal-body>
    <mdb-modal-footer>
      <div class="d-flex float-right">
        <awgt-std-button type="button" @click="onClose()" class="command-button"
          >Close</awgt-std-button
        >
      </div>
    </mdb-modal-footer>
  </div>
</template>

<style lang="scss" src="@/styles/common.scss"></style>

<script>
import {
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from "mdbvue";
import awgtInput from "../../components/AWGT/AwgtInput";
import { sharedMethods } from "@/shared/shared.js";
import productApi from "@/api/productApi";
import AwgtStdButton from "@/components/AWGT/AwgtStdButton";

export default {
  name: "related-product-selection-dialog",
  components: {
    AwgtStdButton,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    awgtInput,
  },

  data() {
    return {
      isDialogVisible: false,
      selectedRelatedProducts: [],
      supplierProductsSoldSeparately: [],
    };
  },

  directives: {},

  props: {
    //Pass in the product component to be edited.
    value: {
      type: Array,
      required: true,
    },

    providerSupplierReference: {
      type: String,
      required: true,
    },

    currentProductReference: {
      type: String,
      required: true,
    },
  },

  computed: {},

  filters: {},

  watch: {
    value(to, from) {
      if (to != from) {
        this.selectedRelatedProducts = this.$lodash.cloneDeep(to);
      }
    },
  },

  methods: {
    ...sharedMethods,

    async getSupplierProductsSoldSeparately() {
      let supplierProductsSoldSeparately = await productApi.getProducts(
        null,
        null,
        null,
        null,
        this.providerSupplierReference,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        true,
        true,
        true,
        false,
        true,
        true
      );

      //TODO: Need a getProducts function that returns less fields!

      let self = this;

      this.supplierProductsSoldSeparately = supplierProductsSoldSeparately
        .filter((product) => product.Reference != this.currentProductReference)
        .map((product) => ({
          reference: product.Reference,
          name: product.Name,
          checked:
            self.selectedRelatedProducts.findIndex(
              (rp) => rp.RelatedProductReference == product.Reference
            ) != -1,
        }));
    },

    onVisible() {
      if (this.isDialogVisible == false) {
        this.isDialogVisible = true;
        this.loadFormData();
      }
    },

    onClose() {
      let relatedProducts = this.supplierProductsSoldSeparately
        .filter((product) => product.checked == true)
        .map((product) => ({
          RelatedProductReference: product.reference,
          Name: product.name,
          ProductRelationType: "PRT_R",
        }));
      this.$emit("input", relatedProducts);
      this.closeDialog();
    },

    loadFormData() {
      this.selectedRelatedProducts = this.$lodash.cloneDeep(this.value);
      this.getSupplierProductsSoldSeparately().catch(() => {
        // Errors have been logged
      });
    },

    closeDialog() {
      this.$emit("close");
      this.isDialogVisible = false;
    },
  },

  created() {
    this.loadFormData();
  },
};
</script>
