var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center",
        },
        [
          _vm._t("default"),
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button",
              staticStyle: { "min-width": "100px" },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.AddGroup()
                },
              },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "edit" } }),
              _vm._v("Add "),
            ],
            1
          ),
        ],
        2
      ),
      _vm.chosenGroups.length > 0
        ? _c(
            "div",
            { staticClass: "pl-3 py-2" },
            [
              _vm._v(" This product is in the following product groups: "),
              _vm._l(_vm.chosenGroups, function (group, index) {
                return _c("ul", { key: index }, [
                  _c("li", [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-row justify-content-between",
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", [_vm._v(" " + _vm._s(group.Name) + " ")]),
                          group.OtherProductsInGroup != undefined
                            ? _c(
                                "div",
                                [
                                  _vm._v(" Other products in this group are: "),
                                  _vm._l(
                                    group.OtherProductsInGroup,
                                    function (inGroup, index2) {
                                      return _c("ul", { key: index2 }, [
                                        _c("li", [
                                          _vm._v(_vm._s(inGroup.Name)),
                                        ]),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          [
                            _c(
                              "awgt-std-button",
                              {
                                staticClass: "command-button",
                                staticStyle: { "min-width": "100px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.DeleteGroup(index)
                                  },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mr-1",
                                  attrs: { icon: "trash-alt" },
                                }),
                                _vm._v("Delete "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "mdb-modal",
        { attrs: { show: _vm.showEditor } },
        [
          _c("mdb-modal-header", [
            _c("h1", { staticClass: "text-center" }, [
              _vm._v("Reseller Product Groups"),
            ]),
          ]),
          _c(
            "mdb-modal-body",
            [
              _vm.groupAlreadyChosenErrorVisible == true
                ? _c("div", { staticClass: "mb-2 text-danger" }, [
                    _c("span", [_vm._v("This group has already been chosen.")]),
                  ])
                : _vm._e(),
              _vm.groupAlreadyExistingErrorVisible == true
                ? _c("div", { staticClass: "mb-2 text-danger" }, [
                    _c("span", [
                      _vm._v(
                        "This is an existing group, please select it from the list."
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.groupNameEmptyVisible == true
                ? _c("div", { staticClass: "mb-2 text-danger" }, [
                    _c("span", [_vm._v("Please supply a name for the group.")]),
                  ])
                : _vm._e(),
              _c(
                "span",
                { staticClass: "mr-5", staticStyle: { width: "120px" } },
                [_vm._v("Group Type")]
              ),
              _c("awgt-input", {
                attrs: {
                  id: "groupType_Existing",
                  type: "radio",
                  name: "GroupTypeGroup",
                  label: "Existing",
                  radioValue: "existing",
                },
                model: {
                  value: _vm.groupType,
                  callback: function ($$v) {
                    _vm.groupType = $$v
                  },
                  expression: "groupType",
                },
              }),
              _c("awgt-input", {
                attrs: {
                  id: "groupType_New",
                  type: "radio",
                  name: "GroupTypeGroup",
                  label: "New",
                  radioValue: "new",
                },
                model: {
                  value: _vm.groupType,
                  callback: function ($$v) {
                    _vm.groupType = $$v
                  },
                  expression: "groupType",
                },
              }),
              this.groupType == "existing"
                ? _c(
                    "div",
                    [
                      _c("awgt-std-dropdown", {
                        staticStyle: { width: "450px" },
                        attrs: {
                          items: _vm.resellerSupplierGroups,
                          itemValueProperty: "Name",
                          itemTextProperty: "Name",
                          label: "Group",
                          bg: "",
                          tooltip: _vm.getTooltipsTextByCode(52),
                        },
                        model: {
                          value: _vm.existingGroup,
                          callback: function ($$v) {
                            _vm.existingGroup = $$v
                          },
                          expression: "existingGroup",
                        },
                      }),
                      _vm.existingGroup != null
                        ? _c(
                            "div",
                            [
                              _vm._v(" Other products in this group are: "),
                              _vm._l(
                                _vm.getOtherProductsInGroup(_vm.existingGroup),
                                function (productInGroup, index) {
                                  return _c("ul", { key: index }, [
                                    _c("li", [
                                      _vm._v(_vm._s(productInGroup.Name)),
                                    ]),
                                  ])
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c("awgt-input", {
                    attrs: { bg: "", label: "Title", type: "text" },
                    model: {
                      value: _vm.newGroup,
                      callback: function ($$v) {
                        _vm.newGroup = $$v
                      },
                      expression: "newGroup",
                    },
                  }),
            ],
            1
          ),
          _c(
            "mdb-modal-footer",
            [
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.SaveAndCloseEditor()
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
              _c(
                "awgt-std-button",
                {
                  staticClass: "command-button mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.CloseEditor()
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }