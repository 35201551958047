var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row flex-nowrap flex-grow-1 align-items-center justify-content-end pl-3",
        },
        [
          _c(
            "awgt-std-button",
            {
              staticClass: "command-button mx-2",
              attrs: { type: "button" },
              on: { click: _vm.onEdit },
            },
            [
              _c("mdb-icon", { staticClass: "mr-1", attrs: { icon: "edit" } }),
              _vm._v("Edit "),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
      _vm._l(_vm.data, function (rp, index) {
        return _c(
          "mdb-card",
          {
            key: index,
            staticClass: "py-1 mb-1",
            staticStyle: { "background-color": "#f5f5f5" },
          },
          [
            _vm._v(" " + _vm._s(rp.Name) + " "),
            _c("button", {
              staticClass: "d-flex align-items-center",
              staticStyle: {
                border: "none",
                "background-color": "inherit",
                "text-align": "left",
                color: "inherit",
              },
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.toggleCommercialProductLineExpansion(index)
                },
              },
            }),
            _c(
              "expansion-region",
              {
                staticClass: "mx-3",
                attrs: {
                  toggle: _vm.relatedProductExpansion[index] == true,
                  useVShowConditional: "",
                  tag: "div",
                },
              },
              [_c("mdb-card-body", { staticClass: "pt-1" })],
              1
            ),
          ],
          1
        )
      }),
      _vm.showRelatedProductSelectionDialog == true
        ? _c(
            "mdb-modal",
            {
              attrs: {
                size: "lg",
                show: _vm.showRelatedProductSelectionDialog,
              },
            },
            [
              _c("related-product-selection-dialog", {
                attrs: {
                  providerSupplierReference: _vm.providerSupplierReference,
                  currentProductReference: _vm.currentProductReference,
                },
                on: {
                  close: _vm.onProductSelectionDialogClose,
                  input: _vm.onInput,
                },
                model: {
                  value: _vm.data,
                  callback: function ($$v) {
                    _vm.data = $$v
                  },
                  expression: "data",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-grow-1" }, [
      _c("span", { staticClass: "flex-fill font-weight-bolder pl-2" }, [
        _vm._v("Component"),
      ]),
      _c("span", { staticClass: "flex-fill font-weight-bolder" }, [
        _vm._v("Price"),
      ]),
      _c("span", { staticClass: "flex-fill font-weight-bolder" }, [
        _vm._v("Validity"),
      ]),
      _c("span", { staticStyle: { width: "60px" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }